<template>
  <section class="content-block-item-filter">
    <Preloader v-if="!dataLoaded" />
    <template v-if="dataLoaded">
      {{ $store.state["contentBlockItem/filterQuery"] }}

      <div v-if="vlm" class="row">
        <SiteSelect
          v-model="filter.site"
          :options="sites"
          :label="$t('hp.filter.site')"
          class="col-lg-3"
          @input="setFilterSite"
        />
      </div>

      <Select
        v-else
        v-model="filter.site"
        :options="sites"
        :label="$t('hp.filter.site')"
        no-empty-value
        disable-form-group
        @change="setFilterSite"
        :compact-width="true"
      />
      <div class="content-block-item-filter__items">
        <label class="content-block-item-filter__label">{{ $t('hp.filter.visible_blocks') }}</label>
        <div class="content-block-item-filter__items-template">
          <template
            v-for="contentBlock in contentBlocks"
          >
            <label
              v-if="contentBlock.siteId === filter.site && contentBlock.hidden === false"
              class="content-block-item-filter__item"
              :class="{ 'content-block-item-filter__item--active': isContentBlockActive(contentBlock.id) }"
              :key="contentBlock.id"
            >
              <input
                type="checkbox"
                v-model="filter.contentBlocks"
                :value="contentBlock.id"
                @change="setFilter"
                :id="'filter_content_block_' + contentBlock.id"
              >
              {{ contentBlock.title }}
              <span
                v-if="isContentBlockActive(contentBlock.id)"
                class="content-block-item-filter__close-icon"
              >
                &#215;
              </span>
            </label>
          </template>
        </div>
        <button
          class="content-block-item-filter__btn-reset"
          @click="resetFilter"
          data-test="hp_reset_filter"
        >
          {{ $t('hp.filter.reset') }}
        </button>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'
import { MODULE_CONTENT_BLOCK_ITEM } from '@/model/ValueObject/UserPermissionModules'
import Preloader from '@/components/preloader/Preloader'
import Select from '@/components/form/select/Select'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'ContentBlockItemFilter',
  data () {
    return {
      dataLoaded: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters(['vlm']),
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    },
    contentBlocks () {
      const contentBlocks = this.$store.getters['contentBlock/all']
      return contentBlocks.filter(contentBlock => contentBlock.type === CONTENT_BLOCK_TYPE_ARTICLE)
    },
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_CONTENT_BLOCK_ITEM)
    }
  },
  components: {
    SiteSelect,
    Select,
    Preloader
  },
  methods: {
    init () {
      const contentBlocks = this.$store.getters['contentBlock/all']
      if (contentBlocks.length === 0) {
        this.getContentBlocks()
      } else {
        this.dataLoaded = true
      }
    },
    getContentBlocks () {
      this.dataLoaded = false
      this.$store.dispatch('contentBlock/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
        .catch(error => {
          this.dataLoaded = true
          console.log(error)
        })
    },
    isContentBlockActive (id) {
      if (this.filter.contentBlocks && this.filter.contentBlocks.indexOf(id) > -1) {
        return true
      }
    },
    setFilterSite () {
      this.filter.contentBlocks = []
      this.setFilter()
      const siteName = this.$store.getters['site/getSiteNameById'](this.filter.site)
      this.$gtm.trackView(this.$route.name, siteName)
    },
    setFilter () {
      this.$store.commit('contentBlock/setLimit', 50)
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    },
    resetFilter () {
      this.filter.contentBlocks = []
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
  .content-block-item-filter {
    display: grid;
    grid-gap: rem(20px);
    &__items {
      display: grid;
      grid-gap: rem(10px);
    }
    &__items-template {
      display: flex;
      flex-wrap: wrap;
      gap: rem(10px);
    }
    &__item {
      background: #F5F6FA;
      border: 1px solid #8FA7D8;
      border-radius: rem(4px);
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      color: #465674;
      padding: rem(5px) rem(10px);
      &--active {
        background: #8FA7D8;
        color: #fff;
      }
      &:not(.content-block-item-filter__item--active) {
        &:hover {
          background: #8FA7D8;
          color: #fff;
        }
      }
    }
    &__label {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      color: #8A96AC;
    }
    &__close-icon {
      margin-left: rem(5px);
      transform: scale(1.5);
      display: inline-block;
      position: relative;
      top: 1px;
    }
    &__btn-reset {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      width: -moz-fit-content;
      width: fit-content;
      background: #E9596F;
      border-radius: rem(4px);
      border: none;
      color: #fff;
      padding: rem(6px) rem(15px);
      cursor: pointer;
      &:hover {
        background: darken(#E9596F, 5%);
      }
    }
  }
</style>
